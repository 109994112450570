pre[class*='language-'] {
  color: theme('colors.gray.50');
}

.token.tag,
.token.class-name,
.token.selector,
.token.selector .class,
.token.selector.class,
.token.function {
  color: theme('colors.rose.400');
}

.token.attr-name,
.token.keyword,
.token.rule,
.token.pseudo-class,
.token.important {
  color: theme('colors.gray.300');
}

.token.module {
  color: theme('colors.rose.400');
}

.token.attr-value,
.token.class,
.token.string,
.token.property {
  color: theme('colors.blue.300');
}

.token.punctuation,
.token.attr-equals {
  color: theme('colors.gray.500');
}

.token.unit,
.language-css .token.function {
  color: theme('colors.teal.200');
}

.token.comment,
.token.operator,
.token.combinator {
  color: theme('colors.gray.400');
}
