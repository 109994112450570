@tailwind base;

:root {
  --secondary: #a7e6ac;
  --primary: #50c0c0;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 1px;
}

.leaflet-popup-content {
  margin: 0px !important;
}

@tailwind components;
@tailwind utilities;

html {
  @apply h-full;
}
body {
  @apply h-full;
}
div#__next {
  @apply h-full;
}
main {
  @apply h-full;
}

.shadow-bottom {
  box-shadow: 0 6px 6px -4px #bbdbeb;
}

.shadow-bottom-sm {
  box-shadow: 0 2px 4px -2px #bbdbeb;
}

/* @import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap"); */

@font-face {
  font-family: "Cinzel";
  src: url("../assets/fonts/Cinzel/Cinzel-Medium.ttf");
}