.w-md-editor-bar {
  position: absolute;
  cursor: s-resize;
  right: 0;
  bottom: 0;
  margin-top: -11px;
  margin-right: 0;
  width: 14px;
  z-index: 3;
  height: 10px;
  border-radius: 0 0 3px 0;
  -webkit-user-select: none;
          user-select: none;
}
.w-md-editor-bar svg {
  display: block;
  margin: 0 auto;
}
.w-md-editor-area {
  overflow: auto;
  border-radius: 5px;
}
.w-md-editor-text {
  min-height: 100%;
  position: relative;
  text-align: left;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  font-size: 14px !important;
  line-height: 18px !important;
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
}
.w-md-editor-text-pre,
.w-md-editor-text-input,
.w-md-editor-text > .w-md-editor-text-pre {
  margin: 0;
  border: 0;
  background: none;
  box-sizing: inherit;
  display: inherit;
  font-family: inherit;
  font-family: var(--md-editor-font-family) !important;
  font-size: inherit;
  font-style: inherit;
  -webkit-font-variant-ligatures: inherit;
          font-variant-ligatures: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  tab-size: inherit;
  text-indent: inherit;
  text-rendering: inherit;
  text-transform: inherit;
  white-space: inherit;
  overflow-wrap: inherit;
  word-break: inherit;
  word-break: normal;
  padding: 0;
}
.w-md-editor-text-pre {
  position: relative;
  margin: 0px !important;
  pointer-events: none;
  background-color: transparent !important;
}
.w-md-editor-text-pre > code {
  padding: 0 !important;
  font-family: var(--md-editor-font-family) !important;
  font-size: 14px !important;
  line-height: 18px !important;
}
.w-md-editor-text-input {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  resize: none;
  color: inherit;
  overflow: hidden;
  outline: 0;
  padding: inherit;
  -webkit-font-smoothing: antialiased;
}
.w-md-editor-text-pre,
.w-md-editor-text-input {
  min-height: 20px;
  word-wrap: pre;
  word-break: break-word;
  white-space: pre-wrap;
}
/**
 * Hack to apply on some CSS on IE10 and IE11
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /**
    * IE doesn't support '-webkit-text-fill-color'
    * So we use 'color: transparent' to make the text transparent on IE
    * Unlike other browsers, it doesn't affect caret color in IE
    */
  .w-md-editor-text-input {
    color: transparent !important;
  }
  .w-md-editor-text-input::selection {
    background-color: #accef7 !important;
    color: transparent !important;
  }
}
.w-md-editor-text-pre .punctuation {
  color: var(--color-prettylights-syntax-comment, #8b949e) !important;
}
.w-md-editor-text-pre .token.url,
.w-md-editor-text-pre .token.content {
  color: var(--color-prettylights-syntax-constant, #0550ae) !important;
}
.w-md-editor-text-pre .token.title.important {
  color: var(--color-prettylights-syntax-markup-bold, #24292f);
}
.w-md-editor-text-pre .token.code-block .function {
  color: var(--color-prettylights-syntax-entity, #8250df);
}
.w-md-editor-text-pre .token.bold {
  font-weight: unset !important;
}
.w-md-editor-text-pre .token.title {
  line-height: unset !important;
  font-size: unset !important;
  font-weight: unset !important;
}
.w-md-editor-text-pre .token.code.keyword {
  color: var(--color-prettylights-syntax-constant, #0550ae) !important;
}
.w-md-editor-text-pre .token.strike,
.w-md-editor-text-pre .token.strike .content {
  color: var(--color-prettylights-syntax-markup-deleted-text, #82071e) !important;
}
.w-md-editor-toolbar-child {
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 0 0 1px var(--md-editor-box-shadow-color), 0 0 0 var(--md-editor-box-shadow-color), 0 1px 1px var(--md-editor-box-shadow-color);
  background-color: var(--md-editor-background-color);
  z-index: 1;
  display: none;
}
.w-md-editor-toolbar-child.active {
  display: block;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar {
  border-bottom: 0;
  padding: 3px;
  border-radius: 3px;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li {
  display: block;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li button {
  width: -webkit-fill-available;
  height: initial;
  box-sizing: border-box;
  padding: 3px 4px 2px 4px;
  margin: 0;
}
.w-md-editor-toolbar {
  border-bottom: 1px solid var(--md-editor-box-shadow-color);
  background-color: var(--md-editor-background-color);
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px 3px 0 0;
  -webkit-user-select: none;
          user-select: none;
  flex-wrap: wrap;
}
.w-md-editor-toolbar.bottom {
  border-bottom: 0px;
  border-top: 1px solid var(--md-editor-box-shadow-color);
  border-radius: 0 0 3px 3px;
}
.w-md-editor-toolbar ul,
.w-md-editor-toolbar li {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: initial;
}
.w-md-editor-toolbar li {
  display: inline-block;
  font-size: 14px;
}
.w-md-editor-toolbar li + li {
  margin: 0;
}
.w-md-editor-toolbar li > button {
  border: none;
  height: 20px;
  line-height: 14px;
  background: none;
  padding: 4px;
  margin: 0 1px;
  border-radius: 2px;
  text-transform: none;
  font-weight: normal;
  overflow: visible;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  white-space: nowrap;
  color: var(--color-fg-default);
}
.w-md-editor-toolbar li > button:hover,
.w-md-editor-toolbar li > button:focus {
  background-color: var(--color-neutral-muted);
  color: var(--color-accent-fg);
}
.w-md-editor-toolbar li > button:active {
  background-color: var(--color-neutral-muted);
  color: var(--color-danger-fg);
}
.w-md-editor-toolbar li > button:disabled {
  color: var(--md-editor-box-shadow-color);
  cursor: not-allowed;
}
.w-md-editor-toolbar li > button:disabled:hover {
  background-color: transparent;
  color: var(--md-editor-box-shadow-color);
}
.w-md-editor-toolbar li.active > button {
  color: var(--color-accent-fg);
  background-color: var(--color-neutral-muted);
}
.w-md-editor-toolbar-divider {
  height: 14px;
  width: 1px;
  margin: -3px 3px 0 3px !important;
  vertical-align: middle;
  background-color: var(--md-editor-box-shadow-color);
}
.w-md-editor {
  text-align: left;
  border-radius: 3px;
  padding-bottom: 1px;
  position: relative;
  color: var(--color-fg-default);
  --md-editor-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --md-editor-background-color: var(--color-canvas-default, #ffffff);
  --md-editor-box-shadow-color: var(--color-border-default, #d0d7de);
  box-shadow: 0 0 0 1px var(--md-editor-box-shadow-color), 0 0 0 var(--md-editor-box-shadow-color), 0 1px 1px var(--md-editor-box-shadow-color);
  background-color: var(--md-editor-background-color);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
}
.w-md-editor.w-md-editor-rtl {
  direction: rtl !important;
  text-align: right !important;
}
.w-md-editor.w-md-editor-rtl .w-md-editor-preview {
  right: unset !important;
  left: 0;
  text-align: right !important;
  box-shadow: inset -1px 0 0 0 var(--md-editor-box-shadow-color);
}
.w-md-editor.w-md-editor-rtl .w-md-editor-text {
  text-align: right !important;
}
.w-md-editor-toolbar {
  height: -webkit-fit-content;
  height: fit-content;
}
.w-md-editor-content {
  height: 100%;
  overflow: auto;
  position: relative;
  border-radius: 0 0 3px 0;
}
.w-md-editor .copied {
  display: none !important;
}
.w-md-editor-input {
  width: 50%;
  height: 100%;
}
.w-md-editor-text-pre > code {
  word-break: break-word !important;
  white-space: pre-wrap !important;
}
.w-md-editor-preview {
  width: 50%;
  box-sizing: border-box;
  box-shadow: inset 1px 0 0 0 var(--md-editor-box-shadow-color);
  position: absolute;
  padding: 10px 20px;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 0 0 5px 0;
  display: flex;
  flex-direction: column;
}
.w-md-editor-preview .anchor {
  display: none;
}
.w-md-editor-preview .contains-task-list li.task-list-item {
  list-style: none;
}
.w-md-editor-show-preview .w-md-editor-input {
  width: 0%;
  overflow: hidden;
  background-color: var(--md-editor-background-color);
}
.w-md-editor-show-preview .w-md-editor-preview {
  width: 100%;
  box-shadow: inset 0 0 0 0;
}
.w-md-editor-show-edit .w-md-editor-input {
  width: 100%;
}
.w-md-editor-show-edit .w-md-editor-preview {
  width: 0%;
  padding: 0;
}
.w-md-editor-fullscreen {
  overflow: hidden;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100% !important;
}
.w-md-editor-fullscreen .w-md-editor-content {
  height: 100%;
}
